import React, { useEffect, useState } from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Auto_Sell_Token_Report = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Date", accessor: "Date" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Token", accessor: "Token" },
      { Header: "Sell Rate", accessor: "Sell_Rate" },
      { Header: "Type", accessor: "Type" },  
      { Header: "Transaction Id", accessor: "txn" },          
    ],
  });

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  
  const Auto_Sell_Token_Report_API = async () => {
    try {
      let responce = await API?.post(
        `/AutoSellTokenHistory`,
        {
          uid: userId,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("BuyTokenHistory-->", responce);
      responce = responce?.data.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Token: item?.usdamount,
          Date: item?.edate1,
          Sell_Rate: item?.coin_rate,
          Amount: item?.netamount,
          Type: item?.Other_remark,
          txn: item?.txn,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const resetFilters = () => {
    Auto_Sell_Token_Report_API();
  };

  useEffect(() => {
    Auto_Sell_Token_Report_API();
  }, []);


  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="d-flex align-items-center justify-content-between py-3">
            <div className="fs-18 text-white fw-bold">
              Auto Sell Token Report
            </div>
            {/* <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Auto Sell</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:;">
                        <i className="bx bx-home-alt text-white" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Auto Sell Token Report
                    </li>
                  </ol>
                </nav>
              </div>
            </div> */}
          </div>
          <br />
          <div className="row mx-0">
            <div className="col-12 jashja">
            <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={referralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Auto_Sell_Token_Report;
