import React from "react";
import "./DashFooter.css";

const DashFooter = () => {
  return (
    <div>
      <footer class="page-footer footerDasssh">
        <p class="mb-0">Copyright © 36tclub.org 2024. All right reserved.</p>
      </footer>
    </div>
  );
};

export default DashFooter;
