import "./Buy_Token_Report.css";
import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";


const Buy_Token_Report = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Date", accessor: "Date" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Purchased Token", accessor: "Purchased_Token" },
      { Header: "Buy Rate", accessor: "Buy_Rate" },
      { Header: "Transaction ID", accessor: "Transaction_ID" },     
      { Header: "Remark", accessor: "Buy_rank" },      
    ],
  });

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const Buy_Token_Report_API = async () => {
    try {
      let responce = await API?.post(
        `/BuyTokenHistory`,
        {
          uid: userId,
          fdate: fromDateFilter,
          tdate: toDateFilter,
          "txnid": ""
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("BuyTokenHistory-->", responce);
      responce = responce?.data.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          Purchased_Token: item?.Coinvalue,
          Date: item?.edate1,
          Buy_Rate: item?.coin_rate,
          Amount: item?.usdamount,
          Transaction_ID:item?.txn,
          Buy_rank:item?.Buy_rank,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const resetFilters = () => {
    setFromDateFilter("");
    setToDateFilter("");
    Buy_Token_Report_API();
  };

  useEffect(() => {
    Buy_Token_Report_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                Buy Token History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Buy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Buy Token History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginLeft: 10 }}>
            <div className="col-md-3">
              <label className="text-white fs-12">Enter From Date</label>
              <input
                type="date"
                name="from_date"
                id="from_date"
                className="form-control shadow-none bgmain"
                placeholder="Enter From Date"
                onChange={(e) => setFromDateFilter(e.target.value)}

              />
            </div>
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-12">Enter To Date</label>
              <input
                type="date"
                name="to_date"
                id="to_date"
                className="form-control bgmain shadow-none"
                onChange={(e) => setToDateFilter(e.target.value)}
              />
            </div>
            <br />
            <br />
            <div className="col-md-auto col-3">
              <input
                type="Find"
                name="to_date"
                defaultValue="Find"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                onClick={Buy_Token_Report_API}
              />
            </div>
            <div className="col-md-auto col-3">
              <input
                type="Reset"
                name="to_date"
                defaultValue="Reset"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none"
                onClick={resetFilters}

              />
            </div>
          </div>
          <br />
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};
export default Buy_Token_Report;