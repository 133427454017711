import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function PrivateRoutes() {
    const { clubAuth, jwtToken, ipAddress } = useSelector(
        (state) => state.Auth
      );
  // console.log("PrivateRoutes", clubAuth);

    
      return (
        <>
          {clubAuth === true && jwtToken.length > 20 && ipAddress ? (
            <Outlet />
          ) : (
            <Navigate to="/Login" />
          )}
        </>
      );
}
