import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Deposite_History = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "Transaction_ID", accessor: "Transaction_ID" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Hash", accessor: "Hash_ID" },
      { Header: "Date", accessor: "Date" },
    ],
  });

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const DirectIncomeReport = async () => {
    try {
      let responce = await API?.post(
        `/Deposite_FundByContractHistory`,
        {
          uid: userId
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("DepositeReport-->", responce);
      responce = responce?.data.data;
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item?.row,
          uid:item?.uid,
          Date: item?.edate1,
          Transaction_ID: item?.txnid,
          Hash_ID: (
            <a
              href={
                item.CoinType === "BNB"
                  ? `https://bscscan.com/tx/${item.txn}`
                  : `https://polygonscan.com/tx/${item.txn}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              View Txn
            </a>
          ),
          Amount: item?.usd,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    DirectIncomeReport();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                Deposit History
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Deposit</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Deposit History
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Deposite_History;
