import React, { useEffect, useState } from "react";
import "./Add_Fund.css";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";
import toast from "react-hot-toast";
import DOMPurify from "dompurify";

const Add_Fund = () => {
  const [amount, setAmount] = useState("");
  const [touserid, setTouserid] = useState("");
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const dashboardData = useSelector((state) => state.Auth.dashboardData);
  const { TransferRapidFund } = dashboardData || {};


  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  const handleAmount = (e) => {
    const value1 = DOMPurify.sanitize(
      e.target.value.replace(/[^0-9]/gi, "").substring(0, 4)
    );

    setAmount((prev) => {
      if (prev !== value1) {
        return value1;
      }
      return prev;
    });
  };

  const handleUserIdChange = (e) => {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 6);
    const sanitizeValue = DOMPurify.sanitize(inputText);
    setTouserid(sanitizeValue);
  };

  const Usertouser_Rapid_API = async () => {
    try {
      if (amount <= 0) {
        toast.error("Invalid amount");
        return;
      }
      
      if(touserid === userId){
        toast.error("Enter Others User Id");
        return;
      }

      const res = await API.post(
        "/Usertouser_RapidFundTransfer",
        {
          uid: userId,
          amount: amount,
          touserid: touserid,
          remark: "User To User Rapid Fund Transfer",
          WalletType: "TransferRapidFund",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Usertouser_Rapid_API-->", res);
      if (res.data.data.result === "Fund Transfered Successfully") {
        toast.success(res.data.data.result);
      } else {
        toast.error(res.data.data.result);
      }
      // window.location.reload();

      navigate("/DashBoard");
    } catch (error) {
      console.error("Error while Usertouser_Rapid_API:", error);
      dispatch(
        updateAuth({
          clubAuth: false,
          userId: null,
          jwtToken: null,
          ipAddress: null,
        })
      );
      navigate("/");
    } finally {
      // setVisible(false);
      setAmount("");
      setTouserid("");
    }
  };

 

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">
                  Rapid Fund Transfer
                </div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">User to User</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          User to User Rapid Fund Transfer
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Available Rapid Balance : {TransferRapidFund}
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>User Id</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="amountInput"
                              readOnly
                              value={userId}
                              style={{ width: "94%" }}
                            />
                          </div>
                          <div className="col-sm-4 col-6">
                            <label>Enter Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="amountInput"
                              min={1}
                              step={1}
                              placeholder="Enter Amount"
                              value={amount}
                              onChange={handleAmount}
                              style={{ width: "94%" }}
                            />
                          </div>
                          <div className="col-sm-4 col-6">
                            <label>To User Id</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="amountInput"
                              min={1}
                              step={1}
                              placeholder="Enter To User Id"
                              value={touserid}
                              onChange={handleUserIdChange}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        {/* <div className="row justify-content-center">
                          <div className="col-12 mt-3 text-center">
                            <div className="btn btn-Green py-2 px-3 paymentadd">
                              $ 10
                            </div>
                            <div className="btn btn-Green py-2 px-3 paymentadd">
                              $ 50
                            </div>
                            <div className="btn btn-Green py-2 px-3 paymentadd">
                              $ 100
                            </div>
                            <div className="btn btn-Green py-2 px-3 paymentadd">
                              $ 500
                            </div>
                            <div className="btn btn-Green py-2 px-3 paymentadd">
                              $ 1000
                            </div>
                          </div>
                          <div className="col-12 text-center py-3">
                            <button className="btn btn-Green py-2 px-5 restart">
                              Reset
                            </button>
                          </div>
                        </div> */}
                        <br />
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            <button className="btn btn_hxn withdraw_btn w-100 py-3 shadow-none"
                            onClick={()=> Usertouser_Rapid_API() }>
                              Rapid Fund Transfer
                            </button>
                          </div>
                          {/* <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Deposit Amount Should Be Greater Than $50.
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Add_Fund;
