import React from "react";
import crossmargin from "../../assets/images/crossmargin.png";
import hedgepoloniex from "../../assets/images/hedgepoloniex.png";
import radeallassets from "../../assets/images/radeallassets.png";
import automatically from "../../assets/images/automatically.png";
import cardtocrypto from "../../assets/images/cardtocrypto.png";
import trustedsecure from "../../assets/images/trustedsecure.png";
import trade from "../../assets/images/trade.png";
import support227 from "../../assets/images/support227.png";
import ethereum5051 from "../../assets/images/ethereum5051.png";
import ethereum5052 from "../../assets/images/ethereum5052.png";
import ethereum5050 from "../../assets/images/ethereum5050.png";
import ethereum5053 from "../../assets/images/ethereum5053.png";
import cryptocurrency from "../../assets/images/cryptocurrency.png";
import currencyCategory71949 from "../../assets/images/currencyCategory71949.png";
import solanalogo from "../../assets/images/solanalogo.png";
import currencyCategory6bd4a from "../../assets/images/currencyCategory6bd4a.png";
import FAQ from "../FAQ/FAQ";
import Ready_to_Exchange from "../Ready_to_Exchange/Ready_to_Exchange";
import { Link } from "react-router-dom";
import defi from "../../assets/images/defi.png";
import crypto from "../../assets/images/crypto.png";
const EarnCrypto = () => {
  return (
    <div>
      <>
        <section id="Defi" className="earn_crypto pt-120 pb-120">
          <div className="container">
            <div className="row gy-5 gy-md-6">
              <div className="col-12">
                <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                  <h2>
                   ABOUT{" "}
                    <span className="p1-color">DEFI</span>
                  </h2>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="earn_crypto__cardone rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <div className="d-flex align-items-start justify-content-between flex-wrap gap-3 mb-8 mb-sm-10 mb-md-15">
                    <div>
                      <h2 className="mb-2 p1-color">
                      What is a Decentralized Finance?
                      </h2>
                    </div>
                    <p>
                    Decentralized finance refers to financial products and services available to anyone with an internet connection who can use Ethereum. The markets are always open with DeFi, and there are no centralized authorities to block your payments and access to any financial transactions.
<br></br>
                    However, Bitcoin was the first DeFi application. It allows you to have the ownership and control of the value and send it anywhere worldwide. This financial disruption enables many people who do not trust each other to agree on a ledger of accounts without a trusted intermediary.
                    </p>
                   
                  </div>
                </div>
              </div>
              <div className="col-xl-6">

              <img
            className="imgs"
            src={defi}
            alt="light"
          />

              </div>
              <div className="col-xl-12">
              <h2 className="mb-2 p1-color text-center">
                    How Does DeFi Work?
                      </h2>
                      <p>DeFi provides financial services using cryptocurrencies and smart contracts, eliminating the need for intermediaries such as guarantors. By utilizing DeFi technology, people can execute lending (in which users can lend out their cryptocurrencies and receive interest instantly); receiving loan; making p2p lending without a broker; saving cryptocurrency and earning higher interest rate than a bank, and buying derivatives.

Decentralized apps (dApps) allow two or more parties to do the transactions directly using blockchain technology and smart contracts without the involvement and costs of intermediaries. Theoretically, it is a fair, accessible, and open digital marketplace. However, in reality, this is not always the case.

Fintech companies use DeFi technology to provide savings accounts, loans, securities trading, and insurance. Additionally, people can use DeFi services and dApps in coins, stablecoins, tokens, digital wallets, DeFi mining (liquidity mining), yield farming, staking, trading, borrowing, lending, and saving using smart contracts.</p>
              </div>
              </div>
              <br></br>
              <div className="row gy-5 gy-md-6" id="Cryptocurrency">
                <div className="col-xl-6">
                <img
            className="imgs"
            src={crypto}
            alt="light"
          />

                </div>
              <div className="col-xl-6">
                <div className="earn_crypto__cardone rounded-4 p-6 p-md-8 h-100 wow fadeInUp">
                  <div className="d-flex align-items-start justify-content-between flex-wrap gap-3 mb-8 mb-sm-10 mb-md-15">
                    <div>
                      <h2 className="mb-6 p1-color">
                      What is Cryptocurrency?
                      </h2>
                    </div>
                    <p>
                    Cryptocurrency represents a form of currency that is digital, encrypted, and decentralized. Unlike traditional fiat currencies, cryptocurrencies operate without the central authority of banks or governments. They were pioneered by the enigmatic Satoshi Nakamoto in 2009 with the launch of Bitcoin, which introduced the world to blockchain technology—a distributed ledger that maintains the integrity of transaction data through decentralization and cryptographic hashing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <>
          <FAQ />
        </>
      </>
    </div>
  );
};

export default EarnCrypto;
