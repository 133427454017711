import React, { useEffect, useState } from 'react'
import DashFooter from '../DashFooter/DashFooter';
import Table_Buttons from '../Table_Buttons/Table_Button';
import Table from '../Table/Table';
import DashHeader from '../DashHeader/DashHeader';
import { updateAuth } from '../../Redux/AuthSlice';
import { API } from '../../Utility/API';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export default function ReciveFund() {
    const [referralApi, setreferralApi] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [listPerpage, setlistPerpage] = useState(10);
  
    const indexOfLastPost = currentPage * listPerpage;
    const indexOfFirstPage = indexOfLastPost - listPerpage;
    const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  
    var [matching_income, set_matching_income] = useState({
      cols: [
        { Header: "S.No", accessor: "sr" },
        { Header: "From UserId", accessor: "touserid" },
        { Header: "From Wallet", accessor: "towallet" },
        { Header: "Amount", accessor: "amount" },
        { Header: "Date", accessor: "Date" },
        { Header: "Remark", accessor: "remark" },
      ],
    });
  
    const { userId, jwtToken } = useSelector((state) => state.Auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const RapidRecieveHistory_API = async () => {
      try {
        let responce = await API?.post(
          `/RapidFundreceivedHistory`,
          {
            uid: userId,
          },
          {
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log("RapidRecieveHistory_API", responce);
        responce = responce.data.data[0];
        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            sr: item.row,
            touserid: item.uid,
            amount: item.amount,
            Date: item.dd,
            remark: item.remark,
            towallet: `${item.wallet.substring(0, 6)}...${item.wallet.substring(
                item.wallet.length - 4
              )}`,
          });
        });
  
        setreferralApi(arr);
      } catch (e) {
        console.log("Error While calling RapidRecieveHistory_API", e);
        if (
          e.response.data.showableMessage ===
          "Please login to access this resource"
        ) {
          dispatch(
            updateAuth({
              clubAuth: false,
              userId: null,
              jwtToken: null,
              ipAddress: null,
            })
          );
          navigate("/");
        }
      }
    };
  
    useEffect(() => {
        RapidRecieveHistory_API();
    }, []);
  
    return (
      <div>
        <DashHeader />
        <div className="page-wrapper px-0">
          <div className="page-content adjustmentsection">
            <div className="row mx-0  py-3">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-18 text-white fw-bold">
                    Rapid Fund Recieve History Report
                  </div>
                  <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Recieve History</div>
                    <div className="ps-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                          <li className="breadcrumb-item">
                            <a href="">
                              <i className="bx bx-home-alt text-white" />
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page">
                            Rapid Fund
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 jashja">
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>
    );
  };
