import React, { useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

const Matrix_Downline = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [totalCount, settotalCount] = useState(50);
  const [userid, setUserId] = useState("");

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  console.log("currentPost", currentPost);
  // setcurrentPost(referralApi.slice(indexOfFirstPage, indexOfLastPost));

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "usr" },
      { Header: "Wallet", accessor: "f_name" },
      { Header: "Level", accessor: "date" },
      { Header: "Latest Package", accessor: "Latest" },
      { Header: "Gross Package", accessor: "Package" },
      { Header: "Date", accessor: "Date" },
    ],
  });
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Matrix Downline</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Matrix Downline</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Matrix Downline
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-3">
            <br />
            <br />
            <div className="col-md-3">
              <label className="text-white fs-14">Level</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
              >
                <option
                  value=""
                  style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                >
                  All
                </option>
                <option
                  value={0}
                  style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                >
                  Level 1
                </option>
                <option
                  value={1}
                  style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                >
                  Level 2
                </option>
                <option
                  value={2}
                  style={{ color: "rgb(255, 255, 255)", background: "#797c13" }}
                >
                  Level 3
                </option>
              </select>
            </div>
            <br />
            <br />
            <div class="col-md-auto mt-1">
              <input
                type="submit"
                name="to_date"
                class="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                value="Find"
              />
              <input
                type="submit"
                name="to_date"
                class="btn btn-primary mt_5 bgmain border-0 px-3 mx-2 shadow-none w-auto"
                value="Reset"
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...referralApi]} columns={matching_income.cols} />
              <Table_Buttons
                indexOfFirstPage={indexOfFirstPage}
                indexOfLastPost={indexOfLastPost}
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={totalCount}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Matrix_Downline;
