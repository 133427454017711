import React from "react";

const FAQ = () => {
  return (
    <div>
      <section id="FAQs" className="rewards_faq pt-120 pb-120 position-relative">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-xl-7">
              <div className="rewards_faq__content">
                <h2 className="mb-5 mb-md-6 wow fadeInUp p1-color">FAQs</h2>
                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                  Explore our FAQs for fast, informative answers to frequently
                  asked questions and common concerns.
                </p>
                <div className="accordion-section">
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        What is 36t Club?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        The 36T Club is a global decentralized community leveraging the Bsc blockchain to offer members unique networking opportunities, exclusive travel experiences, and automated reward distribution.


                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                        How do decentralized rewards work?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Decentralized rewards are facilitated by smart contracts, which automatically execute and distribute rewards to members based on their participation and contributions to the community, ensuring a fair and transparent reward system.


                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Is the 36T Club accessible to anyone?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Yes, the 36T Club is designed to be accessible to all, regardless of geographic location or background. We offer various membership packages to suit different needs and ensure that the club is as inclusive as possible.


                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Are transactions within 36T Club secure?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Yes, transactions are secured by the robust Bsc blockchain protocol, ensuring safety, integrity, and reliability of all financial movements within the club.

                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       What makes the 36T Club transparent?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Transparency is achieved through the use of blockchain technology where all transactions and distributions are recorded on a public ledger, visible and verifiable by any member at any time.


                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                      How to join 36t degree club?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Click Register Button in the Menu Bar, Connect your BSC based web3 wallet , after the wallet is connected, click register . A Pop up will appear , type your sponsor ID and select position left or right , then click register. Congratulations, you have successfully register your account in 360 Degree Club.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       What are the membership packages available?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        We offer a range of packages, from Basic to Crown Diamond, each providing different levels of access and benefits. Details are available on our website to help you select the one that’s right for you.


                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Can I earn from the 36T Club without traveling?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Absolutely! While travel is a key component of our club, members can earn rewards through various other community-driven activities and networking opportunities.


                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       How to buy Shatri Token?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Register and add funds , click on the Buy token option in the dashboard menu,  buy your desired amount of shatri token.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-single accsingle mb-2 wow fadeInUp">
                    <h5 className="header-area d-flex align-items-center justify-content-between">
                      <button
                        className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                        type="button"
                      >
                       Can I upgrade my membership?
                      </button>
                    </h5>
                    <div className="content-area">
                      <div className="content-body">
                        <p>
                        Members have the flexibility to upgrade their membership package at any time to enjoy additional benefits and higher reward potential.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 wow fadeInUp">
                  <span>Can't see your question listed above?</span>
                  <a
                    href="https://t.me/club36T_official"
                    className="d-flex align-items-center gap-1 p6-color astyle"
                  >
                    Visit our Help Center{" "}
                    <i className="ti ti-arrow-narrow-right fs-four mt-1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="rewards_faq__thumb">
                <img
                  src="assets/images/faq-thumb.png"
                  className="max-un leftright-animation"
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section id="Contact" className="rewards_faq pt-120 pb-120 position-relative">
        <div className="container">
          <div className="row align-items-center justify-content-center gy-8">
            <div className="col-md-12">
            <h2 class="mb-2 p1-color text-center ">Contact Us</h2>
            <p className="text-center">Get In Touch</p>

             <form method="post" class="row cardSection px-sm-3 px-2 py-sm-4 py-3 gy-4">
                <div class="col-12 mt-2 mt-sm-0"><input class="form-control form-control-lg" type="text" placeholder="Your Name" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><input class="form-control form-control-lg" type="email" placeholder="Your E-Mail" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><input class="form-control form-control-lg" type="email" placeholder="Subject" aria-label=".form-control-lg example"/></div>
                <div class="col-12"><textarea placeholder="Message" class="form-control" id="exampleFormControlTextarea1" rows="6"></textarea></div>
              </form>
              <button class="formbtn btn py-2 px-4 fw-bold">Submit</button>

            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default FAQ;
