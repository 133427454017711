import React from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import blackicon from "../../assets/images/blackicon.png";
import greenicon from "../../assets/images/greenicon.png";
import redicon from "../../assets/images/redicon.png";

const Matrix_Tree = () => {
  return (
    <div>
      <DashHeader />
      <div className="page-wrapper" id="home">
        <div className="page-content pb-5">
          <div className="d-flex align-items-center justify-content-between pb-5">
            <div className="fs-18 text-white fw-bold">Matrix Tree</div>
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Tree</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0">
                    <li className="breadcrumb-item">
                      <a href="javascript:;">
                        <i className="bx bx-home-alt text-white" />
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Matrix Tree
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-md-6">
              <div className="search-box">
                <div className="form-horizontal ng-pristine ng-valid">
                  <div className="form-wrapper">
                    <input
                      className="form-control idinput srch_frm bg-white shadow-none text-black"
                      id="ddluserid"
                      name="userId"
                      maxLength={8}
                      placeholder="Search"
                      defaultValue={962998}
                    />
                    <span
                      className="field-validation-valid"
                      data-valmsg-for="userId"
                      data-valmsg-replace="true"
                    />
                    <button
                      id="#submit"
                      type="button"
                      className="btn btn-sm btn-danger topmargin submit_btn border-0 shadow-none"
                    >
                      Submit
                    </button>
                    <button
                      id="#home"
                      type="button"
                      className="btn btn-sm btn-danger border-0 shadow-none topmargin float_rt"
                    >
                      Home
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger shadow-none topmargin float_rt border-0"
                    >
                      BACK
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="tree_icon">
                <div className="tree_name">
                  <img
                    src={greenicon}
                    width="40px"
                    height="40px"
                  />
                  Activate
                </div>
                <div className="tree_name">
                  <img
                    src={redicon}
                    width="40px"
                    height="40px"
                  />
                  In-Active
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-md-12 pb-5">
              <div className="card pb-5">
                <div className="MintingHistory_Main Mobile_MintingHistory_Main">
                  <div className="TreeImgMian ">
                    <ul class="tree vertical">
                      <li className="kzsxjka">
                        <div class="popover__wrapper">
                          <a>
                            <h2 class="popover__title">
                              <img
                                src={blackicon}
                                alt="Image description"
                                className="img-fluid"
                                style={{ width: 60 }}
                              />
                            </h2>
                          </a>
                          <div class="popover__content">
                            <div class="modsddal-areaksnc">
                              <h6 style={{ borderBottom: "1px solid white" }}>
                                Sponsor :320760
                              </h6>
                              <div className="contteett d-flex">
                                <div style={{ borderRight: "1px solid white" }}>
                                  <p>Registration Date : </p>
                                  <p>Package : </p>
                                  <p>Total Left : </p>
                                  <p>Total Left Active : </p>
                                  <p>Total Left Business : </p>
                                  <p>Today Left Business : </p>
                                </div>
                                <div>
                                  <p>Activation Date : </p>
                                  <p>Package amount : </p>
                                  <p>Total Right : </p>
                                  <p>Total Right Active : </p>
                                  <p>Total Right Business : </p>
                                  <p>Today Right Business : </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul>
                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <img
                                    src={blackicon}
                                    alt="Image description"
                                    className="img-fluid"
                                    style={{ width: 60 }}
                                  />
                                </h2>
                              </a>
                              <div class="popover__content">
                                <div class="modsddal-areaksnc">
                                  <h6
                                    style={{
                                      borderBottom: "1px solid white",
                                    }}
                                  >
                                    Sponsor :320760
                                  </h6>
                                  <div className="contteett d-flex">
                                    <div
                                      style={{
                                        borderRight: "1px solid white",
                                      }}
                                    >
                                      <p>Registration Date : </p>
                                      <p>Package : </p>
                                      <p>Total Left : </p>
                                      <p>Total Left Active : </p>
                                      <p>Total Left Business : </p>
                                      <p>Today Left Business : </p>
                                    </div>
                                    <div>
                                      <p>Activation Date : </p>
                                      <p>Package amount : </p>
                                      <p>Total Right : </p>
                                      <p>Total Right Active : </p>
                                      <p>Total Right Business : </p>
                                      <p>Today Right Business : </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <img
                                        src={blackicon}
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                      />
                                    </h2>
                                  </a>
                                  <div class="popover__content">
                                    <div class="modsddal-areaksnc">
                                      <h6
                                        style={{
                                          borderBottom: "1px solid white",
                                        }}
                                      >
                                        Sponsor :320760
                                      </h6>
                                      <div className="contteett d-flex">
                                        <div
                                          style={{
                                            borderRight: "1px solid white",
                                          }}
                                        >
                                          <p>Registration Date : </p>
                                          <p>Package : </p>
                                          <p>Total Left : </p>
                                          <p>Total Left Active : </p>
                                          <p>Total Left Business : </p>
                                          <p>Today Left Business : </p>
                                        </div>
                                        <div>
                                          <p>Activation Date : </p>
                                          <p>Package amount : </p>
                                          <p>Total Right : </p>
                                          <p>Total Right Active : </p>
                                          <p>Total Right Business : </p>
                                          <p>Today Right Business : </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <img
                                        src={blackicon}
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                      />
                                    </h2>
                                  </a>
                                  <div class="popover__content">
                                    <div class="modsddal-areaksnc">
                                      <h6
                                        style={{
                                          borderBottom: "1px solid white",
                                        }}
                                      >
                                        Sponsor :320760
                                      </h6>
                                      <div className="contteett d-flex">
                                        <div
                                          style={{
                                            borderRight: "1px solid white",
                                          }}
                                        >
                                          <p>Registration Date : </p>
                                          <p>Package : </p>
                                          <p>Total Left : </p>
                                          <p>Total Left Active : </p>
                                          <p>Total Left Business : </p>
                                          <p>Today Left Business : </p>
                                        </div>
                                        <div>
                                          <p>Activation Date : </p>
                                          <p>Package amount : </p>
                                          <p>Total Right : </p>
                                          <p>Total Right Active : </p>
                                          <p>Total Right Business : </p>
                                          <p>Today Right Business : </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <div class="popover__wrapper">
                              <a>
                                <h2 class="popover__title">
                                  <img
                                    src={blackicon}
                                    alt="Image description"
                                    className="img-fluid"
                                    style={{ width: 60 }}
                                  />
                                </h2>
                              </a>
                              <div class="popover__content">
                                <div class="modsddal-areaksnc">
                                  <h6
                                    style={{
                                      borderBottom: "1px solid white",
                                    }}
                                  >
                                    Sponsor :320760
                                  </h6>
                                  <div className="contteett d-flex">
                                    <div
                                      style={{
                                        borderRight: "1px solid white",
                                      }}
                                    >
                                      <p>Registration Date : </p>
                                      <p>Package : </p>
                                      <p>Total Left : </p>
                                      <p>Total Left Active : </p>
                                      <p>Total Left Business : </p>
                                      <p>Today Left Business : </p>
                                    </div>
                                    <div>
                                      <p>Activation Date : </p>
                                      <p>Package amount : </p>
                                      <p>Total Right : </p>
                                      <p>Total Right Active : </p>
                                      <p>Total Right Business : </p>
                                      <p>Today Right Business : </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <img
                                        src={blackicon}
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                      />
                                    </h2>
                                  </a>
                                  <div class="popover__content">
                                    <div class="modsddal-areaksnc">
                                      <h6
                                        style={{
                                          borderBottom: "1px solid white",
                                        }}
                                      >
                                        Sponsor :320760
                                      </h6>
                                      <div className="contteett d-flex">
                                        <div
                                          style={{
                                            borderRight: "1px solid white",
                                          }}
                                        >
                                          <p>Registration Date : </p>
                                          <p>Package : </p>
                                          <p>Total Left : </p>
                                          <p>Total Left Active : </p>
                                          <p>Total Left Business : </p>
                                          <p>Today Left Business : </p>
                                        </div>
                                        <div>
                                          <p>Activation Date : </p>
                                          <p>Package amount : </p>
                                          <p>Total Right : </p>
                                          <p>Total Right Active : </p>
                                          <p>Total Right Business : </p>
                                          <p>Today Right Business : </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div class="popover__wrapper">
                                  <a>
                                    <h2 class="popover__title">
                                      <img
                                        src={blackicon}
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                      />
                                    </h2>
                                  </a>
                                  <div class="popover__content">
                                    <div class="modsddal-areaksnc">
                                      <h6
                                        style={{
                                          borderBottom: "1px solid white",
                                        }}
                                      >
                                        Sponsor :320760
                                      </h6>
                                      <div className="contteett d-flex">
                                        <div
                                          style={{
                                            borderRight: "1px solid white",
                                          }}
                                        >
                                          <p>Registration Date : </p>
                                          <p>Package : </p>
                                          <p>Total Left : </p>
                                          <p>Total Left Active : </p>
                                          <p>Total Left Business : </p>
                                          <p>Today Left Business : </p>
                                        </div>
                                        <div>
                                          <p>Activation Date : </p>
                                          <p>Package amount : </p>
                                          <p>Total Right : </p>
                                          <p>Total Right Active : </p>
                                          <p>Total Right Business : </p>
                                          <p>Today Right Business : </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a>
                                        <h2 class="popover__title">
                                          <img
                                            src={blackicon}
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                          />
                                        </h2>
                                      </a>
                                      <div class="popover__content">
                                        <div class="modsddal-areaksnc">
                                          <h6
                                            style={{
                                              borderBottom: "1px solid white",
                                            }}
                                          >
                                            Sponsor :320760
                                          </h6>
                                          <div className="contteett d-flex">
                                            <div
                                              style={{
                                                borderRight: "1px solid white",
                                              }}
                                            >
                                              <p>Registration Date : </p>
                                              <p>Package : </p>
                                              <p>Total Left : </p>
                                              <p>Total Left Active : </p>
                                              <p>Total Left Business : </p>
                                              <p>Today Left Business : </p>
                                            </div>
                                            <div>
                                              <p>Activation Date : </p>
                                              <p>Package amount : </p>
                                              <p>Total Right : </p>
                                              <p>Total Right Active : </p>
                                              <p>Total Right Business : </p>
                                              <p>Today Right Business : </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Matrix_Tree;
