import React, { useState, useEffect } from "react";
import DashHeader from "../DashHeader/DashHeader";
import DashFooter from "../DashFooter/DashFooter";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { loadWeb3 } from "../../Utility/contract";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

import {
  Abi_Main,
  Abi_USDT,
  ContractAddress_Main,
  ContractAddress_USDT,
} from "../../Utility/ContractAddress";

const Deposite = () => {
  const user = useSelector((state) => state.Auth.userId);
  const jwt = useSelector((state) => state.Auth.jwtToken);
  let [Amount, setAmount] = useState(0);
  let [connected, setconnected] = useState("Wallet is locked");
  let [loading, setloading] = useState(false);
  let [USDT, setUSDT] = useState(0);
  const [BNBBalance, setBNBBalance] = useState(0);
  const [Wallet_Address, setWallet_Address] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Loader component
  const Loader = () => (
    <div className="loader text-center">
      <Spinner animation="border" variant="primary" />
      <p>Loading...</p>
    </div>
  );

  const Calculatevalue = (val) => {
    const Addvalues = val;
    const Totalvalue = parseFloat(Addvalues) + parseFloat(Amount);
    setAmount(Totalvalue);
  };

  const reset = async () => {
    setAmount(0);
  };

  const GetUserDetails = async () => {
    try {
      let responce = await API?.get(`userDashboard?uid=${user}`, {
        headers: {
          Authorization: `${jwt}`,
          "Content-Type": "application/json",
        },
      });
      responce = responce.data.data[0][0];

      setWallet_Address(responce.btcaddress);
    } catch (e) {
      console.log("userDetails error", e);
      // console.log("userDetails error", e.response.data);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            wtcAuth: false,
            token: null,
            userId: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const USDTBalance = async () => {
    try {
      let acc = await loadWeb3();
      let contract = await new window.web3.eth.Contract(
        Abi_USDT,
        ContractAddress_USDT
      );
      let result = await contract.methods.balanceOf(acc.toString()).call();
      // console.log("Result ",result.toString());
      result = parseFloat(result) / parseFloat(1000000000000000000);
      // console.log("Result ", result);
      //result = window.web3.utils.fromWei(result);
      setUSDT(result.toFixed(2));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const BNB_Balance = async () => {
    try {
      let acc = await loadWeb3();

      let myBalance = 0;
      myBalance = await window.web3.eth.getBalance(acc);
      myBalance = await window.web3.utils.fromWei(myBalance, "ether");

      // console.log("BNB balance",myBalance);

      setBNBBalance(parseFloat(myBalance).toFixed(4));
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();

      if (acc == "No Wallet") {
        setconnected("Wallet is locked");
      } else if (acc == "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSC Network");
        setconnected("Wrong Network");
      } else {
        setconnected("Wallet is Connected");
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const Deposite_FUND = async (e) => {
    let acc = await loadWeb3();
    setloading(true);
    if (acc === "No Wallet" || acc === undefined) {
      toast.error("No Wallet Connected");
      setloading(false);
    } else if (acc == "Wrong Network") {
      //toast.error("Wrong Network Please Connect BSC Scan Network");
      console.log("Wrong Network Please Connect Polygon Scan Network");
      setloading(false);
    } else {
      if (Wallet_Address.toUpperCase() == acc.toUpperCase()) {
        if (Amount !== 0) {
          if (USDT < Amount) {
            toast.error("Insufficient USDT Balance");
            setloading(false);
          } else {
            setloading(true);
            let usdtamountValue = window.web3.utils.toWei(
              Amount.toString(),
              "ether"
            );

            const gasPrice = await window.web3.eth.getGasPrice();
            const approveBlock = window.web3.eth.getBlock("latest");

            try {
              let contract_Main = await new window.web3.eth.Contract(
                Abi_Main,
                ContractAddress_Main
              );
              let contract_USDT = await new window.web3.eth.Contract(
                Abi_USDT,
                ContractAddress_USDT
              );

              let approveCall = await contract_USDT.methods
                .approve(ContractAddress_Main, usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });
              toast.success("USDT Approved Succesful");

              let sellCall = await contract_Main.methods
                .deposit(usdtamountValue)
                .send({
                  from: acc,
                  gasLimit: approveBlock.gasLimit,
                  gasPrice: await window.web3.eth.getGasPrice(),
                });

              let res = await API.post(
                "Deposite_FundByContract",
                {
                  uid: user,
                  addreslist: acc,
                  usd: Amount,
                  txn: sellCall.transactionHash,
                  CoinType: "BNB",
                },
                {
                  headers: {
                    Authorization: `${jwt}`,
                    "Content-Type": "application/json",
                  },
                }
              );
              console.log("activation-->", res);
              toast.success("Transaction is succesful");
              if (res.data.success == true) {
                setloading(false);
                toast.success("Activation is under process");
                navigate("/dashboard");
              }
            } catch (err) {
              console.log("error while calling fuction sell", err);
              if (
                e.response.data.showableMessage ==
                "Please login to access this resource"
              ) {
                dispatch(
                  updateAuth({
                    clubAuth: false,
                    userId: null,
                    jwtToken: null,
                    ipAddress: null,
                  })
                );
                navigate("/");
              }
            }
          }
        } else {
          toast.error("Please Enter Amout First");
          setloading(false);
        }
      } else {
        toast.error("Invalid Wallet address");
        setloading(false);
      }
    }
  };

  useEffect(() => {
    walletConnected();
    USDTBalance();
    BNB_Balance();
    GetUserDetails();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Deposit Fund</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Deposit</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Deposit Fund
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3" />
            <div className="col-lg-6">
              <div className="modal-dialog">
                <div className="modal-content exchange_width">
                  <div className="modal-header exchange_hd w-100">
                    <h4 className="w-100 text-center pt-2">
                      Available USDT : {USDT} USDT
                    </h4>
                  </div>
                  <div className="modal-header exchange_hd w-100">
                    <h5 className="w-100 text-center pt-2">{connected}</h5>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default table-wrapper ng-pristine ng-valid ng-valid-maxlength mt-0">
                      <div className="panel-body">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-sm-4 col-6">
                            <label>Enter USD Amount</label>
                          </div>
                          <div className="col-sm-8 col-6">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="amountInput"
                              value={Amount}
                              readOnly
                              defaultValue={0}
                              style={{ width: "94%" }}
                            />
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-12 mt-3 text-center">
                            {/* <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(10)}
                            >
                              $ 10
                            </div> */}
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(10)}
                            >
                              $ 10
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(50)}
                            >
                              $ 50
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(100)}
                            >
                              $ 100
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(500)}
                            >
                              $ 500
                            </div>
                            <div
                              className="btn btn-Green py-2 px-3 paymentadd"
                              onClick={() => Calculatevalue(1000)}
                            >
                              $ 1000
                            </div>
                          </div>
                          <div className="col-12 text-center py-3">
                            <button
                              className="btn btn-Green py-2 px-5 restart"
                              onClick={() => setAmount(0)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-center mx-0">
                          <div className="col-sm-6 row align-items-center justify-content-center">
                            {loading == true ? (
                              <>
                                <Loader />
                              </>
                            ) : (
                              <>
                                <button
                                  className="btn btn_hxn withdraw_btn w-100 py-3 shadow-none"
                                  onClick={() => Deposite_FUND()}
                                >
                                  Deposit Fund
                                </button>
                              </>
                            )}
                          </div>
                          <div className="col-12 ">
                            <p className="text-white fs-6 fw-bold text-center py-2 mb-0 notetext">
                              Note: Deposit Amount Should Be Greater Than $ 10.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Deposite;
