import React from "react";
import hero_shape from "../../assets/images/hero_shape.png";
import upcomingcard7 from "../../assets/images/upcomingcard7.png";
import round from "../../assets/images/round.png";
import lightone from "../../assets/images/lightone.png";
import lighttwo from "../../assets/images/lighttwo.png";
import coinbase from "../../assets/images/coinbase.png";
import bitfinex from "../../assets/images/bitfinex.png";
import binance from "../../assets/images/binance.png";
import ripple from "../../assets/images/ripple.png";
import steemit from "../../assets/images/steemit.png";
import About from "../../assets/images/about.png";
import subscription from "../../assets/images/subscription.png";
import snapshot from "../../assets/images/snapshot.png";
import lottery from "../../assets/images/lottery.png";
import redemption from "../../assets/images/redemption.png";
import Markets from "../Markets/Markets";
import Gettt from "../Gettt/Gettt";
import EarnCrypto from "../EarnCrypto/EarnCrypto";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Hero = () => {
  return (
    <div>
      <Header />
      <section className="hero_area pt-120 pb-16 position-relative">
        <div className="container z-1">
          <div className="row justify-content-center mt-8 mt-sm-13 mt-md-0">
            <div className="col-xl-9">
              <div className="hero_area__content pt-17 pt-sm-20 pt-lg-0 text-center">
                <span className="fs-five py-2 px-3 px-sm-5 mb-4 wow fadeInUp">
                  We Revolutionize Crypto
                </span>
                <h1 className="display-three mb-5 mb-md-6 wow fadeInUp">
                  We Revolutionize Crypto
                </h1>
                <p className="mb-8 mb-md-10 wow fadeInUp">
                Unlock the potential of your financial future and seize the opportunity to invest in shatri, a revolutionary token, where innovation, growth, and prosperity converge.

                </p>
                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4 gap-md-6 mb-10 mb-md-13 wow fadeInUp">
                  <Link
                    className="hero_area__content-btnone cmn-btn px-6 px-md-8 py-3 d-center gap-3"
                    to="/Registration"
                  >
                    Get Started
                    <i className="ti ti-chevron-right fs-five px-1 bg4-color p6-color rounded-3 fw-bolder" />
                  </Link>
                  <a href="/36t.pdf" target="_blank"
                    className="hero_area__content-btntwo cmn-btn third-alt px-6 px-md-8 py-3 d-center gap-3"
                    // to="../../36T.pdf"
                  >
                    Explore
                    <i className="ti ti-chevron-right fs-five px-1 bg2-color rounded-3 fw-bolder" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 my-7 wow fadeInUp d-flex flex-column align-items-center justify-content-center">
              <div className="hero_area__thumb wow fadeInUp position-relative">
                <img
                  className="rounded-5 rounded-55"
                  src={upcomingcard7}
                  alt="Hero Banner"
                />
              </div>
              <h4 className="py-8 wow fadeInUp p1-color">Welcome to the Future of Community Building!</h4>
              <p>We're thrilled to unveil 36tclub.org, a groundbreaking project that's here to revolutionize the way we engage with the Metaverse and AI-related products and services. Our mission is simple: to foster a thriving global community that not only embraces the cutting-edge technology of the Metaverse and AI but also empowers its members to generate swift income through the exciting world of Affiliate Marketing.</p><br />
              <p>Join us on this remarkable journey as we bridge the gap between the Metaverse, AI innovation, and Financial opportunities. 36tclub.org is your gateway to a world where possibilities are limitless. Stay tuned for exciting updates and a chance to be a part of something extraordinary!</p>
            </div>
          </div>
        </div>
        <div className="hero_area__shape">
          <img
            className="position-absolute rotated_animattwo"
            src={hero_shape}
            alt="Shape"
          />
        </div>
        <div className="hero_area__lishtone">
          <img
            className="position-absolute opacity-75"
            src={lightone}
            alt="light"
          />
        </div>
        <div className="hero_area__lishttwo">
          <img
            className="position-absolute opacity-75"
            src={lighttwo}
            alt="light"
          />
        </div>
      </section>

      <section className="web3_product how_join bg9-color pt-120 pb-120" id="about">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="col-md-6">
                <div className="how_join__title text-left mb-10 mb-md-15">
                  <h2 className="wow fadeInUp p1-color">ABOUT US</h2>
                  
                  <h4 class="mb-1 text-left p1-color mt-3">
                 Empowering The Future Of Finance
                  </h4>
                  <p>

                  Shatri Token, a revolutionary digital currency poised to transform the landscape of finance. In this forward section, we invite you to embark on a journey of innovation and discovery as we unveil our vision, values, and the roadmap that will guide us towards a future of financial empowerment. With cutting-edge technology, a dedicated team, and a steadfast commitment to transparency and security, we are on a mission to redefine the way we transact and interact with digital assets. Join us as we navigate the path to success, creating a world where financial possibilities know no bounds.

                  </p>
                </div>
            </div>
            <div className="col-md-6">
              <center>
            <img
            className="imgs"
            src={About}
            alt="light"
          />
          </center>
            </div>
            </div>
          </div>
      </section>

      <section className="web3_product how_join bg9-color pt-120 pb-120">
        <div className="container">
          <div className="row gy-5 gy-md-6">
            <div className="how_join__title text-center mb-10 mb-md-15">
              <h2 className="wow fadeInUp p1-color">What Sets Us Apart?</h2>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item  rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={subscription} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Inclusive Governance</h4>
                <p className="mb-6 mb-md-8">
                  With our community-driven governance model, you can harness
                  the power of collective decision-making.
                </p>
                {/* <div className="web3_product__item-btn">
                  <a
                    className="cmn-btn third-alt px-3 py-2 rounded-5"
                    href="#"
                  >
                    <i className="ti ti-arrow-up-right fs-four" />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={snapshot} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Fair Distribution</h4>
                <p className="mb-6 mb-md-8">
                  To ensure a level playing field for all participants, we
                  prioritise equitable token distribution.
                </p>
                
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={lottery} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Transparency</h4>
                <p className="mb-6 mb-md-8">
                  Our dedication to transparency fosters trust by providing a
                  clear picture of the token's history and allocations.
                </p>
                
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 col-xxl-3">
              <div className="web3_product__item how_join__item rounded-3 br2 position-relative wow fadeInUp">
                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                  <img src={redemption} alt="Icons" />
                </div>
                <h4 className="mb-4 mb-md-5">Engagement Rewards</h4>
                <p className="mb-6 mb-md-8">
                  Active participation is rewarded, fostering a thriving
                  community invested in the token's success.
                </p>
               
              </div>
            </div>
            {/* <div className="col-12">
              <div className="text-center mt-2 mt-md-4">
                <Link
                  to="/"
                  className="cmn-btn py-2 px-5 px-md-6 d-inline-flex justify-content-center align-items-center roboto"
                >
                  VIEW MORE <i className="ti ti-chevron-right fs-four" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Markets />
      <Gettt />
      <EarnCrypto />
      <Footer />
    </div>
  );
};

export default Hero;
