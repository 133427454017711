import React, { useEffect, useState } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../Utility/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Sell_Rate_Chart = () => {
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "Number" },
      { Header: "Buy Rate", accessor: "Buy_Rate" },
      { Header: "Sell Rate", accessor: "Sell_Rate" },
      { Header: "USD", accessor: "USD" },      
      { Header: "Token No.", accessor: "Token_No" },
      { Header: "Remark", accessor: "Remark" },
      { Header: "Txn", accessor: "Txn" },
      { Header: "Date", accessor: "Date" },
    ],
  });
     
  const { userId, jwtToken } = useSelector((state) => state.Auth);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const Sell_Token_Report_API = async () => {
    try {
      let responce = await API?.get(`/buysellrequestHistory`,
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("BuyTokenHistory-->", responce);
      responce = responce?.data.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          Number: item.row,
          Buy_Rate: item?.BuyRate,
          Sell_Rate: item?.sellRate,
          USD: item?.USD,
          Token_No: item?.TotalCoin,
          Remark: (
            <button
              className={`btn btn-theme1 text-white px-3 py-1 ${
                item.Remark == "Buy" ? "bgmain" : "sellmain"
              } shadow-none fs-12`}>
              {item?.Remark}
            </button>
          ),
          Txn: item?.Txn,
          Date: item?.dd,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  const resetFilters = () => {
    setFromDateFilter("");
    setToDateFilter("");
    Sell_Token_Report_API();
  };

  useEffect(() => {
    Sell_Token_Report_API();
  }, []);

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper pt-5">
        <div className="page-content adjustmentsection">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="heading text-white fw-bold fs-4 mb-3">
                  Buy / Sell Rate Chart
                </div>
                {/* <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Buy</div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Sell Rate Chart
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-12 jashja">
            <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={referralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Sell_Rate_Chart;
