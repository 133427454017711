import React from "react";
import { Link } from "react-router-dom";
import face from "../../assets/images/face.png";
import insta from "../../assets/images/insta.png";
import tele from "../../assets/images/tele.png";
import twitter from "../../assets/images/twitter.png";
import youtube from "../../assets/images/youtube.png";
const Footer = () => {
  return (
    <div>
      <footer className="footer bg5-color">
        <div className="container">
          <div className="row gy-8 pb-120 justify-content-between"></div>
        </div>
        <div className="container-fluid ">
          <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
            <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
              <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                <div className="coyp-rightarea">
                  <span className="p4-color roboto text-center text-md-start">
                    Copyright 2024 -
                    <Link to="/" className="p4-color">
                    36tclub.org&nbsp;
                    </Link>
                    All Rights Reserved{" "}
                  </span>
                </div>
                <div className="privacy-policay kapqpdknq d-flex align-items-center gap-3">
                  <div class="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                    <a href="https://www.facebook.com/36tclub">
                      <img src={face} style={{width:"50px"}}/>
                      {/* <i class="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2"></i> */}
                    </a>
                    <a href="https://www.instagram.com/club36t/">
                    <img src={insta} style={{width:"50px"}}/>
                      {/* <i class="ti ti-brand-linkedin p4-color fs-four fw-normal p-2"></i> */}
                    </a>
                    <a href="https://t.me/club36T_official">
                    <img src={tele} style={{width:"50px"}}/>
                      {/* <i class="ti ti-brand-instagram p4-color fs-four fw-normal p-2"></i> */}
                    </a>
                    <a href="https://twitter.com/36tcluborg">
                    <img src={twitter} style={{width:"50px"}}/>
                      {/* <i class="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2"></i> */}
                    </a>
                    <a href="https://www.youtube.com/channel/UC6t2W6BzIweWiGhzvSRXB1g">
                    <img src={youtube} style={{width:"50px"}}/>
                      {/* <i class="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2"></i> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
