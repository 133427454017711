import React, { useEffect, useState } from 'react'
import DashFooter from '../DashFooter/DashFooter';
import Table_Buttons from '../Table_Buttons/Table_Button';
import Table from '../Table/Table';
import DashHeader from '../DashHeader/DashHeader';
import { updateAuth } from '../../Redux/AuthSlice';
import { API } from '../../Utility/API';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function RapidFundIncomeReport() {


  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

    const dispatch = useDispatch();
    const navigate = useNavigate();
   
  
  
    const { userId, jwtToken } = useSelector((state) => state.Auth);
  
    const RapidFundIncomeReport_API = async () => {
      try {
        let responce = await API?.post(
          `/RapidFundIncomeReport`,
          {
            uid: userId,
            fdate: "",
            tdate: "",
          },
          {
            headers: {
              Authorization: `${jwtToken}`,
              "Content-Type": "application/json", 
            },
          }
        );
        // console.log("RapidFundIncomeReport_API-->", responce);
        responce = responce?.data?.data[0];
        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            Number: item?.row,
          User_Id: item?.uid,
          fromid: item?.fromid,
          // wallet: item?.wallet,
          Date: item?.dd,
          Remark: ` ${item?.remark}`,
          Amount: `$ ${item?.onamount}`,
          status: item?.status,
          });
        });
  
        setreferralApi(arr);
      } catch (e) {
        console.log("Error While calling Referrer API", e);
        if (
          e.response.data.showableMessage ===
          "Please login to access this resource"
        ) {
          dispatch(
            updateAuth({
              clubAuth: false,
              userId: null,
              jwtToken: null,
              ipAddress: null,
            })
          );
          navigate("/");
        }
      }
    };
  
    useEffect(() => {
        RapidFundIncomeReport_API();
    }, []);
  
  
  
  
  
  
   
  
    var [matching_income, set_matching_income] =  useState({
      cols: [
        { Header: "S.No", accessor: "Number" },
      { Header: "User ID", accessor: "User_Id" },
      // { Header: "From Wallet", accessor: "wallet" },
      // { Header: "Status", accessor: "status" },
      { Header: "On Amount", accessor: "Amount" },
      { Header: " Date", accessor: "Date" },
      { Header: "Remark", accessor: "Remark" },
      ],
    });
    
    return (
      <div>
        <DashHeader />
        <div className="page-wrapper">
          <div className="page-content adjustmentsection">
            <div className="row mx-0  py-3">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fs-18 text-white fw-bold">Rapid Fund Income Report</div>
                  <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                    <div className="breadcrumb-title pe-3">Rapid Fund Income Report</div>
                    <div className="ps-3">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                          <li className="breadcrumb-item">
                            <a href="javascript:;">
                              <i className="bx bx-home-alt text-white" />
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Rapid Fund Income Report
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0">
            <div className="col-12 jashja">
                <Table data={[...currentPost]} columns={matching_income.cols} />
                <Table_Buttons
                  setcurrentPage={setcurrentPage}
                  currentPage={currentPage}
                  totalData={referralApi.length}
                  listPerpage={listPerpage}
                  handleRowsPerPageChange={setlistPerpage}
                />
              </div>
            </div>
          </div>
        </div>
        <DashFooter />
      </div>
    );
  };