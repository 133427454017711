import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import DashboardMain from "./Components/DashboardMain/DashboardMain";
import Adjustment from "./Components/Adjustment/Adjustment";
import Sell_Rate_Chart from "./Components/Sell_Rate_Chart/Sell_Rate_Chart";
import Add_Fund from "./Components/Add_Fund/Add_Fund";
import Fund_Report from "./Components/Fund_Report/Fund_Report";
import Activate_Upgrade from "./Components/Activate_Upgrade/Activate_Upgrade";
import Activate_Upgrade_History from "./Components/Activate_Upgrade_History/Activate_Upgrade_History";
import Buy_Token from "./Components/Buy_Token/Buy_Token";
import Buy_Token_Report from "./Components/Buy_Token_Report/Buy_Token_Report";
import Sell_Token from "./Components/Sell_Token/Sell_Token";
import Sell_Token_Report from "./Components/Sell_Token_Report/Sell_Token_Report";
import Auto_Sell_Token_Report from "./Components/Auto_Sell_Token_Report/Auto_Sell_Token_Report";
import Tree_View from "./Components/Tree_View/Tree_View";
import Direct_User_List from "./Components/Direct_User_List/Direct_User_List";
import Level_Details from "./Components/Level_Details/Level_Details";
import Downline from "./Components/Downline/Downline";
import Matrix_Downline from "./Components/Matrix_Downline/Matrix_Downline";
import Direct_Earning_Income from "./Components/Direct_Earning_Income/Direct_Earning_Income";
import Binary_Earning_Report from "./Components/Binary_Earning_Report/Binary_Earning_Report";
import Matrix_Earning from "./Components/Matrix_Earning/Matrix_Earning";
import Matrix_CTO_Earning from "./Components/Matrix_CTO_Earning/Matrix_CTO_Earning";
import Withdrawal_Direct_Earning from "./Components/Withdrawal_Direct_Earning/Withdrawal_Direct_Earning";
import DirectRewardBusiness from "./Components/DirectRewardBusiness/DirectRewardBusiness";
import Withdrawal from "./Components/Withdrawal/Withdrawal";
import Withdrawal_History from "./Components/Withdrawal_History/Withdrawal_History";
import Matrix_Tree from "./Components/Matrix_Tree/Matrix_Tree";
import TodayDownlineReport from "./Components/TodayDownlineReport/TodayDownlineReport";
import TotalDownlineReport from "./Components/TotalDownlineReport/TotalDownlineReport";
import Register from "./Components/Register/Register";
import Login from "./Components/Login/Login";
import { Toaster } from "react-hot-toast";
import ShortTermReward_incomeReport from "./Components/ShortTermReward_incomeReport/ShortTermReward_incomeReport";
import LongTermReward_incomeReport from "./Components/LongTermReward_income/LongTermReward_incomeReport";
import PrivateRoutes from "./Utility/PrivateRoutes";
import RapidFundIncomeReport from "./Components/Rapid Fund INcome/RapidFundIncomeReport";
import Activate_Upgrade_Fund from "./Components/Activate_Upgrade/Activate_Upgrade_Fund";
import Direct_Leg_Bussiness from "./Components/Direct_Leg_Bussiness/Direct_Leg_Bussiness";
import ReciveFund from "./Components/Recive Fund/ReciveFund";
import Deposite from "./Components/DespositeFund/Deposite";
import Deposite_History from "./Components/DespositeFund/Deposite_History";
import SelectType from "./Components/Buy_Token/SelectType";
import LifeTimeReward from "./Components/LifeTimeReward/LifeTimeReward";
import LifeTimeRewardCTO from "./Components/LifeTimeRewardCTO/LifeTimeRewardCTO";

function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/" element={<Hero />}></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route path="/Registration" element={<Register />}></Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/DashBoard" element={<DashboardMain />}></Route>
          <Route path="/Adjustment" element={<Adjustment />}></Route>
          <Route path="/Sell_Rate_Chart" element={<Sell_Rate_Chart />}></Route>
          <Route path="/Deposite" element={<Deposite />}></Route>
          <Route
            path="/Deposite_History"
            element={<Deposite_History />}
          ></Route>
          <Route path="/Add_Fund" element={<Add_Fund />}></Route>
          <Route path="/Fund_Report" element={<Fund_Report />}></Route>
          <Route path="/reciveFund_Report" element={<ReciveFund />}></Route>
          <Route
            path="/Activate_Upgrade"
            element={<Activate_Upgrade />}
          ></Route>
          <Route
            path="/Activate_Upgrade_History"
            element={<Activate_Upgrade_History />}
          ></Route>

          <Route path="/SelectType" element={<SelectType />}></Route>
          <Route path="/Buy_Token" element={<Buy_Token />}></Route>
          <Route
            path="/Buy_Token_Report"
            element={<Buy_Token_Report />}
          ></Route>
          <Route path="/Sell_Token" element={<Sell_Token />}></Route>
          <Route
            path="/Sell_Token_Report"
            element={<Sell_Token_Report />}
          ></Route>
          <Route
            path="/Auto_Sell_Token_Report"
            element={<Auto_Sell_Token_Report />}
          ></Route>
          <Route path="/Tree_View" element={<Tree_View />}></Route>
          <Route path="/Matrix_Tree" element={<Matrix_Tree />}></Route>
          <Route
            path="/Direct_User_List"
            element={<Direct_User_List />}
          ></Route>
          <Route path="/Level_Details" element={<Level_Details />}></Route>
          <Route path="/Downline" element={<Downline />}></Route>
          <Route path="/Matrix_Downline" element={<Matrix_Downline />}></Route>
          <Route
            path="/TodayDownlineReport"
            element={<TodayDownlineReport />}
          ></Route>
          <Route
            path="/TotalDownlineReport"
            element={<TotalDownlineReport />}
          ></Route>
          <Route
            path="/Direct_Earning_Income"
            element={<Direct_Earning_Income />}
          ></Route>
          <Route
            path="/Binary_Earning_Report"
            element={<Binary_Earning_Report />}
          ></Route>
          <Route path="/roi_income" element={<Matrix_Earning />}></Route>
          <Route
            path="/RapidFundIncomeReport"
            element={<RapidFundIncomeReport />}
          />
          <Route
            path="/roilevelincome"
            element={<Matrix_CTO_Earning />}
          ></Route>
          <Route
            path="/salary_income"
            element={<Withdrawal_Direct_Earning />}
          ></Route>
          <Route
            path="/ShortTermReward_incomeReport"
            element={<ShortTermReward_incomeReport />}
          ></Route>
          <Route
            path="/LongTermReward_incomeReport"
            element={<LongTermReward_incomeReport />}
          ></Route>
          <Route
            path="/WithdrawlShare_Income"
            element={<DirectRewardBusiness />}
          ></Route>
          <Route path="/Withdrawal" element={<Withdrawal />}></Route>
          <Route
            path="/Withdrawal_History"
            element={<Withdrawal_History />}
          ></Route>
        </Route>
        <Route
          path="/Activate_Upgrade_Fund"
          element={<Activate_Upgrade_Fund />}
        ></Route>
        <Route
          path="/Direct_Leg_Bussiness"
          element={<Direct_Leg_Bussiness />}
        ></Route>

        <Route
          path="/LifeTimeRewardCTO"
          element={<LifeTimeRewardCTO />}
        ></Route>

        <Route path="/LifeTimeReward" element={<LifeTimeReward />}></Route>
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
