import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clubAuth: false,
  userId: "",
  jwtToken: "",
  ipAddress: "",
  status: "In-Active",
};
export const AuthSlice = createSlice({
  name: "userAuth",
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.clubAuth = action.payload.clubAuth;
      state.userId = action.payload.userId;
      state.jwtToken = action.payload.jwtToken;
      state.ipAddress = action.payload.ipAddress;
    },

    updateStatus: (state, action) => {
      state.status = action.payload.status;
    },
    SetDashboardData: (state, action) => {
      state.dashboardData = action.payload;
    },
  },
});

export const { updateAuth, updateStatus, SetDashboardData } = AuthSlice.actions;
export default AuthSlice.reducer;
